import URLParse from 'url-parse'
import { useContext } from '@nuxtjs/composition-api'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useLink () {
  const { $config, i18n: { localeCodes = [] } } = useContext()

  function isExternalLink (link: string): boolean {
    const storeDomainParsed = new URLParse($config.storeURL)
    const parsed = new URLParse(link, storeDomainParsed.origin)
    return storeDomainParsed.host !== parsed.host
  }

  function cutLocaleTag (link: string): string {
    if (!isExternalLink(link)) {
      const storeDomainParsed = new URLParse($config.storeURL)
      const parsed = new URLParse(link, storeDomainParsed.origin)
      const path = parsed.pathname.split('/').filter(i => Boolean).filter(i => !localeCodes.includes(i))
      parsed.set('pathname', path.join('/'))
      return parsed.pathname + parsed.query
    }

    return link
  }

  return {
    isExternalLink,
    cutLocaleTag
  }
}
