

import { computed, PropType, defineComponent } from '@nuxtjs/composition-api'
import { useLink } from '~/composables/useLink'
import { safeComputed } from '~/modules/helpers'

export default defineComponent({
  name: 'DynamicLink',
  props: {
    link: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup (props) {
    const { isExternalLink, cutLocaleTag } = useLink()

    const isExternal = safeComputed(() => isExternalLink(props.link))

    const tagComponent = safeComputed(() => {
      if (!props.link) { return 'div' }

      if (isExternal.value) {
        return 'a'
      }

      return 'nuxt-link'
    })

    const preparedProps = computed(() => {
      if (!props.link) return {}

      if (isExternal.value) {
        return {
          href: props.link,
          rel: 'nofollow',
          targeted: '_blank'
        }
      }

      return {
        to: cutLocaleTag(props.link)
      }
    })

    return {
      tagComponent,
      preparedProps
    }
  }
})

