
import { PropType } from '@nuxtjs/composition-api'
import { StrapiInspirationBlock } from '~/modules/strapi/types'
import { useImage } from '~/composables'
import { safeComputed } from '~/modules/helpers'
import DynamicLink from '~/components/DynamicLink.vue'

export default {
  name: 'InspirationSectionItem',
  components: { DynamicLink },
  props: {
    block: {
      type: Object as PropType<StrapiInspirationBlock>,
      default: null
    }
  },
  setup (props) {
    const { getMagentoImage } = useImage()
    const imageUrl = safeComputed(() => getMagentoImage(props.block?.image?.url))

    return {
      imageUrl,
      getMagentoImage
    }
  }
}
