
import { PropType, defineComponent } from '@nuxtjs/composition-api'
// @ts-expect-error
import { SwiperOptions } from 'swiper/types'
import { StrapiSectionInspiration } from '~/modules/strapi/types'
import InspirationSectionItem from '~/modules/strapi/components/InspirationSectionItem.vue'
import { useSwiper } from '~/composables/useSwiper'
import { SwiperNavigations } from '~/components/swiper'
import LazyFor from '~/components/LazyFor.vue'

export default defineComponent({
  name: 'InspirationSection',
  components: {
    LazyFor,
    SwiperNavigations,
    InspirationSectionItem
  },
  props: {
    section: {
      type: Object as PropType<StrapiSectionInspiration>,
      default: null
    }
  },
  setup () {
    const { setInstance, swiper: swiperInstance } = useSwiper()

    const swiperDefaultOptions: SwiperOptions = {
      grabCursor: true,
      grid: {
        rows: 2
      },
      slidesPerView: 2,
      spaceBetween: 15,
      breakpoints: {
        767: {
          grid: undefined,
          slidesPerView: 4,
          spaceBetween: 15
        }
      }

    }

    return {
      setInstance,
      swiperDefaultOptions,
      swiperInstance
    }
  }
})
