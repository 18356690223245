
import { PropType, computed } from '@nuxtjs/composition-api'
import { Swiper } from 'swiper'
import BaseButton from '~/components/BaseButton.vue'

export default {
  components: { BaseButton },
  props: {
    instance: {
      type: [Object, null] as PropType<Swiper>,
      required: true
    }
  },
  setup (props) {
    const isBeginning = computed<boolean>(() => props.instance?.isBeginning ?? false)
    const isEnd = computed<boolean>(() => props.instance?.isEnd ?? false)

    return {
      isBeginning,
      isEnd
    }
  }
}
